import TENANT_MAPPINGS from '../configs/tenantMappingConfig'; 

const extractTenantId = () => {
  const hostname = window.location.hostname;
  if (hostname.endsWith("dabs.hu")) {
    const domainParts = hostname.split('.');
    if (domainParts.length > 2) {
      return domainParts[0]; 
    }
  }

  return TENANT_MAPPINGS[hostname] || null; 
};

export { extractTenantId };