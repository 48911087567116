import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import RootLayout from '../layouts/RootLayout';
import HomePage from '../features/home/HomePage'; 
import SupplyPage from '../features/supply/SupplyPage';
import ProfilePage from '../features/profile/ProfilePage';
import BookingPage from '../features/booking/pages/BookingPage';
import ConfirmBookingPage from '../features/booking/pages/ConfirmBookingPage';
import LoginPage from '../features/auth/pages/LoginPage';
import SuccessfulAuthPage from '../features/auth/pages/SuccessfulAuthPage';
import ErrorPage from '../pages/ErrorPage';
import CompleteRegistrationPage from '../features/auth/pages/CompleteRegistrationPage';
import LogoutPage from '../features/auth/pages/LogoutPage';
import { Routes } from "./RouteConstants";
import { ProtectedBookingRoute, ProtectedPrePreserveRoute, ProtectedConfirmBookingRoute, ProtectedProfileRoute, ProtectedDashboardRoute, ProtectedHomeRoute } from './ProtectedRoutes';
import DashboardPage from '../features/dashboard/DashboardPage';
import PreReservePage from '../features/booking/pages/PreReservePage';
import SuccesfulBookingPage from '../features/booking/pages/SuccessfulBookingPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route >
      <Route path='/' element={<RootLayout />} >
        {/* Dashboard */}
        <Route path={Routes.DASHBOARD} element={
          <ProtectedDashboardRoute>
            <DashboardPage />
          </ProtectedDashboardRoute>
        } />

        {/* Home */}
        <Route index element={
          <ProtectedHomeRoute>
            <HomePage />
          </ProtectedHomeRoute>  
        } />

        {/* Supply */}
        <Route path={Routes.SUPPLY} element={<SupplyPage />} />

        {/* Booking */}
        <Route path={Routes.BOOKING} element={
          <ProtectedBookingRoute>
            <BookingPage />
          </ProtectedBookingRoute> 
        } />

        {/* Login */}
        <Route path={Routes.LOGIN} element={<LoginPage />} />

        {/* Pre reserve */}
        <Route path={Routes.PRE_RESERVE} element={
          <ProtectedPrePreserveRoute>
            <PreReservePage />
          </ProtectedPrePreserveRoute>
        } />

        {/* Confirm booking */}
        <Route path={Routes.CONFIRM_BOOKING} element={
          <ProtectedConfirmBookingRoute>
            <ConfirmBookingPage />
          </ProtectedConfirmBookingRoute>
        } />

        {/* Successful booking */}
        <Route path={Routes.SUCCESSFUL_BOOKING} element={<SuccesfulBookingPage /> } />

        {/* Profile */}
        <Route path={Routes.PROFILE} element={
          <ProtectedProfileRoute>
            <ProfilePage />
          </ProtectedProfileRoute>
        } />

        {/* Documents */}
        <Route path={Routes.PRIVAY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={Routes.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />

        {/* Logout */}
        <Route path={Routes.LOGOUT} element={<LogoutPage />} />

        {/* Backend Redirects */}
        <Route path={Routes.COMPLETE_REGISTRATION} element={<CompleteRegistrationPage />} />
        <Route path={Routes.SUCCESSFUL_AUTH} element={<SuccessfulAuthPage />} />
        <Route path={Routes.ERROR} element={<ErrorPage />} />
      </Route>
    </Route>
  )
);

export default router;
