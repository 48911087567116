import { Box, Container, Paper, useTheme, Grid, Typography, Stack, Divider, useMediaQuery } from "@mui/material";
import OpeningHoursItem from "./OpeningHoursItem";
import { Routes } from "../../routes/RouteConstants";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useTenant } from "../../contexts/TenantContext";

export default function HomeLayout() {
  const { tenant } = useTenant();
  const theme = useTheme();
  const downXl = useMediaQuery(theme.breakpoints.down('xl'));
  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="xl" disableGutters={downXl}>
        <Grid container overflow="hidden">
          <Grid item xs={12} md={9} lg={10} display="flex" justifyContent="center">
            <Box
              component="img"
              src="/hero-image.jpg"
              sx={{
                width: "100%",
                maxHeight: { lg: "90vh", xl: "70vh" },
                objectFit: 'cover'
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2} py={2} bgcolor={{ xs: "#323232", md: "transparent" }} pr={{ md: 1 }} display="flex" alignItems="center" justifyContent={{ xs: "center", md: "end" }}>
            <Paper elevation={3} sx={{ p: 5,  minWidth: { xs: "82.5%", sm: "90%", md: "40vw", lg: "30vw", xl: "15vw" }}}>
              <Typography
                variant="h2"
                fontSize={24}
                align="center"
                color="grey"
                fontWeight="bold"
                mb={3}
              >
                Üdvözöllek a 
                <Typography variant="h1" fontSize={32} color={theme.palette.primary.main} style={{ whiteSpace: 'nowrap'}}>
                  {tenant?.name}
                </Typography>
                oldalán!
              </Typography>

               <CustomButton text="Foglalás" onClick={() => navigate(Routes.BOOKING)} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="xl">
        <Paper id="openingHours" elevation={3} sx={{ my: { xs: 3, lg: 4, xl: 5 }, p: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h4" color="secondary" fontWeight="bold" mb={3}>
            Nyitvatartás
          </Typography>

          <Stack width={{ xs: "85%", sm: "70%", md: "35%" }} divider={<Divider orientation="horizontal" flexItem/>}>
            <OpeningHoursItem day={"Hétfő"} hours={"09:00 - 19:00"} />
            <OpeningHoursItem day={"Kedd"} hours={"09:00 - 19:00"} />
            <OpeningHoursItem day={"Szerda"} hours={"09:00 - 19:00"} />
            <OpeningHoursItem day={"Csütörtök"} hours={"09:00 - 19:00"} />
            <OpeningHoursItem day={"Péntek"} hours={"09:00 - 19:00"} />
            <OpeningHoursItem day={"Szombat"} hours={"09:00 - 19:00"} />
            <OpeningHoursItem day={"Vasárnap"} hours={"ZÁRVA"} />
          </Stack >
        </Paper>

        <Box component="iframe"
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2669.10018145059!2d21.63626867666026!3d48.01177435991066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4738a14d6659aab1%3A0x71bb49378f4db5d6!2sBrown%E2%80%99s%20Barber%20Shop!5e0!3m2!1sen!2shu!4v1717836023470!5m2!1sen!2shu"
          width="100%"
          height="50vh"
          loading="lazy"
          border={0}
          referrerpolicy="no-referrer-when-downgrade"
        ></Box>
      </Container>
    </>
  )
}
